export const PAGE_TITLE = "Appointments";
export const PAGE_SINGLE_TITLE = "Appointment";
export const LINK_URL = "appointments";
export const inputFields = {
    name: {
        type: "string",
        required: true,
        title: "Name",
    },
    email: {
        type: "string",
        required: false,
        title: "Email",
    },
    phone: {
        type: "string",
        required: true,
        title: "Phone",
    },
    service: {
        type: "string",
        required: false,
        title: "Service",
    },
    message: {
        type: "string",
        required: false,
        title: "Message",
    },
    meta_title: {
        type: "string",
        required: false,
        title: "Meta Title",
    },
    meta_desc: {
        type: "string",
        required: false,
        title: "Meta Description",
    },

};
export const initialValues = {
    name: "",
    email: "",
    Phone: "",
    service: "",
    message: "",
};

export const view_all_table = [
    {
        name: "Name",
        value: "name",
    },
    {
        name: "Email",
        value: "email",
    },
    {
        name: "Phone",
        value: "phone",
    },
    {
        name: "Service",
        value: "service",
    },
];

export const SIDEBAR_OPTIONS = [
    {
        id: "name",
        field: "name",
        label: "Name",
        type: "string",
        search_type: "search",
    },
    {
        id: "email",
        field: "email",
        label: "Email",
        type: "string",
        search_type: "search",
    },
    {
        id: "service",
        field: "service",
        label: "Service",
        type: "string",
        search_type: "search",
    },

];
