import React from 'react';
import BreadCrumb from '../../components/template/BreadCrumb';
import Header from '../../components/template/Header';
import FilterDateComponent from '../../components/common/FilterDateComponent';
import { useAllDashboards } from '../../shared/hooks/UseDashboard';
import moment from 'moment';
import { Link } from 'react-router-dom';
import { useSelectAllNotification } from '../../shared/hooks/UseNotification';

function Dashboard() {
  // const [data] = useAllDashboards();
  // const { dashboards, dashboards_loading } = data;
  // const [notification_data] = useSelectAllNotification();
  // const { all_notifications } = notification_data;
  // console.log(dashboards);
  return (
    <div className="pace-done">
      <Header />
    </div>
  );
}

export default Dashboard;
