export const PAGE_TITLE = "Webpages";
export const PAGE_SINGLE_TITLE = "Webpage";
export const LINK_URL = "webpages";
export const inputFields = {
    title: {
        type: "string",
        required: true,
        title: "Name",
        inputType: "text",
        slug: true,
    },
    image: {
        type: "file",
        required: false,
        title: "Image",
        inputType: "text",
    },
    description: {
        type: "html",
        required: true,
        title: "Description",
        inputType: "text",
    },
    category: {
        type: "related",
        required: false,
        title: "Category",
        inputType: "text",
        field: "name",
    },
    banner_image: {
        type: "file",
        required: false,
        title: "Banner Image",
        inputType: "text",
    },
    gallery: {
        type: "gallery",
        required: false,
        title: "Gallery",
        inputType: "text",
    },
    slug: {
        type: "slug",
        required: true,
        title: "Slug",
        inputType: "text",
    },
};
export const initialValues = {
    title: "",
};

export const view_all_table = [
    { name: "Title", value: "title" },
    { name: "Image", value: "image", image: true },
    {
        name: "Category",
        value: "category",
        related: true,
        field: "name",
    },
];

export const SIDEBAR_OPTIONS = [
    {
        id: "title",
        field: "title",
        label: "Title",
        type: "string",
        search_type: "search",
        inputType: "text",
        condition: "",
    },
];
