export const PAGE_TITLE = "Collections";
export const PAGE_SINGLE_TITLE = "Collection";
export const LINK_URL = "collections";
export const inputFields = {
  name: {
    type: "string",
    required: true,
    title: "Name",
    slug: true,
  },
  icon: {
    type: "file",
    required: false,
    title: "Icon",
  },
  image: {
    type: "file",
    required: false,
    title: "Image",
  },
  banner_image: {
    type: "file",
    required: false,
    title: "Banner Image",
  },
  description: {
    type: "html",
    required: false,
    title: "Description",
  },
  published_date: {
    type: "string",
    required: false,
    title: "Published Date",
    inputType: "date"

  },
  parent_collection: {
    type: "related",
    required: false,
    title: "Parent Collection",
    field: "name",
  },
  slug: {
    type: "slug",
    required: false,
    title: "Slug",
  },
  meta_title: {
    type: "string",
    required: false,
    title: "Meta Title",
  },
  meta_desc: {
    type: "string",
    required: false,
    title: "Meta Description",
  },
};
export const initialValues = {
  name: "",
  slug: "",
};

export const view_all_table = [
  { name: "Name", value: "name" },
  { name: "Image", value: "image", image: true },
  { name: "Icon", value: "icon", image: true },
  {
    name: "Parent Col. ",
    value: "parent_collection",
    related: true,
    field: "name",
  },
  // { name: "Dynamic Collection", value: "is_dynamic_collection", boolean: true },
];

export const SIDEBAR_OPTIONS = [
  {
    id: "name",
    field: "name",
    label: "Name",
    type: "string",
    search_type: "search",
    inputType: "text",
    condition: "",
  },
];
