export const MENU = [
  {
    label: 'Dashboard',
    link: '/dashboard',
    roles: ['SUPER ADMIN', 'VENDOR'],
  },
  {
    label: 'Home Page',
    link: '#',
    roles: ['SUPER ADMIN', 'VENDOR'],
    menu: [
      {
        label: 'Banners',
        link: '/banners',
        roles: ['SUPER ADMIN'],
      },
      {
        label: 'Mobile Banners',
        link: '/mobilebanners',
        roles: ['SUPER ADMIN'],
      },
      {
        label: 'Webpages',
        link: '/webpages',
        roles: ['SUPER ADMIN'],
      },
      {
        label: 'Faqs',
        link: '/faqs',
        roles: ['SUPER ADMIN'],
      },
    ],
  },
  {
    label: 'Services',
    link: '#',
    roles: ['SUPER ADMIN', 'VENDOR'],
    menu: [
      {
        label: 'Collections',
        link: '/collections',
        roles: ['SUPER ADMIN'],
      },
      {
        label: 'Services',
        link: '/services',
        roles: ['SUPER ADMIN'],
      },
    ],
  },
  {
    label: 'Blogs',
    link: '#',
    roles: ['SUPER ADMIN'],
    menu: [
      {
        label: 'Categories',
        link: '/categorys',
        roles: ['SUPER ADMIN'],
      },
      {
        label: 'Blogs',
        link: '/blogs',
        roles: ['SUPER ADMIN'],
      },
      // {
      //   label: 'Reviews',
      //   link: '/reviews',
      //   roles: ['SUPER ADMIN'],
      // },
    ],
  },
  {
    label: 'Contacts',
    link: '#',
    roles: ['SUPER ADMIN'],
    menu: [
      {
        label: 'Appointments',
        link: '/appointments',
        roles: ['SUPER ADMIN'],
      },
      {
        label: 'Contacts',
        link: '/contacts',
        roles: ['SUPER ADMIN'],
      },
      {
        label: 'Testimonials',
        link: '/testimonials',
        roles: ['SUPER ADMIN'],
      },
    ],
  },



];
