export const PAGE_TITLE = "Services";
export const PAGE_SINGLE_TITLE = "Service";
export const LINK_URL = "services";
export const inputFields = {
    name: {
        type: "string",
        required: true,
        title: "Name",
        slug: true,
    },
    icon: {
        type: "file",
        required: false,
        title: "Icon",
    },
    image: {
        type: "file",
        required: false,
        title: "Image",
    },
    banner_image: {
        type: "file",
        required: false,
        title: "Banner Image",
    },
    description: {
        type: "html",
        required: false,
        title: "Description",
    },
    service_collection: {
        type: "related",
        required: false,
        title: "Service Collection",
        field: "name",
    },
    media: {
        type: "gallery",
        required: false,
        title: "Media",
        inputType: "text",
    },
    slug: {
        type: "slug",
        required: false,
        title: "Slug",
    },
    faqs: {
        type: "array",
        title: "Faqs",
        fields: {
            question: {
                type: "string",
                title: "Question"
            },
            answer: {
                type: "text",
                title: "Question"
            },
        }
    },
    meta_title: {
        type: "string",
        required: false,
        title: "Meta Title",
    },
    meta_desc: {
        type: "string",
        required: false,
        title: "Meta Description",
    },
};
export const initialValues = {
    name: "",
    slug: "",
};

export const view_all_table = [
    { name: "Name", value: "name" },
    { name: "Image", value: "image", image: true },
    { name: "Icon", value: "icon", image: true },
    {
        name: "Collection",
        value: "service_collection",
        related: true,
        field: "name",
    },
    // { name: "Dynamic Service", value: "is_dynamic_service", boolean: true },
];

export const SIDEBAR_OPTIONS = [
    {
        id: "name",
        field: "name",
        label: "Name",
        type: "string",
        search_type: "search",
        inputType: "text",
        condition: "",
    },
];
