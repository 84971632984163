export const PAGE_TITLE = "Banners";
export const PAGE_SINGLE_TITLE = "Banner";
export const LINK_URL = "banners";
export const inputFields = {
  name: {
    type: "string",
    required: false,
    title: "Name",
    inputType: "text",
  },
  short_description: {
    type: "string",
    required: false,
    title: "Short Description",
    inputType: "text",
  },
  image: {
    type: "file",
    required: false,
    title: "Image",
    inputType: "text",
  },
  service_collection: {
    type: "related",
    required: false,
    title: "Service Collection",
    field: "name",
  },
  meta_title: {
    type: "string",
    required: false,
    title: "Meta Title",
  },
  meta_desc: {
    type: "string",
    required: false,
    title: "Meta Description",
  },

};
export const initialValues = {
  name: "",
  slug: "",
};

export const view_all_table = [
  { name: "Name", value: "name" },
  { name: "Image", value: "image", image: true },

  // { name: "Image", value: "image", image: true },
  {
    name: "Collection",
    value: "service_collection",
    related: true,
    field: "name",
  },
];

export const SIDEBAR_OPTIONS = [
  {
    id: "name",
    field: "name",
    label: "Name",
    type: "string",
    search_type: "search",
    inputType: "text",
    condition: "",
  },
];
