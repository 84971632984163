import React, { useEffect } from "react";
import "./assets/css/bootstrap.min.css";
import "./assets/css/app.min.css";
import "./assets/css/custom.css";
import "./assets/css/wysiwyg.css";
import "./assets/css/responsive.css";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { Provider } from "react-redux";
import store from "./store/store";
import { loadUser } from "./store/actions/auth";
import setAuthToken from "./domain/setAuthToken";
import BeforeLoginRoutes from "./shared/Routes/BeforeLoginRoutes";
import PrivateRoutes from "./shared/Routes/PrivateRoutes";

import PageNotFound from "./containers/notfound/PageNotFound";
import Login from "./containers/login/Login";
import Dashboard from "./containers/dashboard/Dashboard";
import Profile from "./containers/profile/Profile";

import AddBanner from "./containers/banners/AddBanner";
import AllBanners from "./containers/banners/AllBanners";
import ViewBanner from "./containers/banners/ViewBanner";
import EditBanner from "./containers/banners/EditBanner";

import AddContact from "./containers/contacts/AddContact";
import AllContacts from "./containers/contacts/AllContacts";
import ViewContact from "./containers/contacts/ViewContact";
import EditContact from "./containers/contacts/EditContact";

import AddNewsletter from "./containers/newsletters/AddNewsletter";
import AllNewsletters from "./containers/newsletters/AllNewsletters";
import ViewNewsletter from "./containers/newsletters/ViewNewsletter";
import EditNewsletter from "./containers/newsletters/EditNewsletter";

import AddBlog from "./containers/blogs/AddBlog";
import AllBlogs from "./containers/blogs/AllBlogs";
import ViewBlog from "./containers/blogs/ViewBlog";
import EditBlog from "./containers/blogs/EditBlog";

import AddCollection from "./containers/collections/AddCollection";
import AllCollections from "./containers/collections/AllCollections";
import ViewCollection from "./containers/collections/ViewCollection";
import EditCollection from "./containers/collections/EditCollection";

import AddService from "./containers/services/AddService";
import AllServices from "./containers/services/AllServices";
import ViewService from "./containers/services/ViewService";
import EditService from "./containers/services/EditService";

import AddMobilebanner from "./containers/mobilebanners/AddMobilebanner";
import AllMobilebanners from "./containers/mobilebanners/AllMobilebanners";
import ViewMobilebanner from "./containers/mobilebanners/ViewMobilebanner";
import EditMobilebanner from "./containers/mobilebanners/EditMobilebanner";

import AddTestimonial from "./containers/testimonials/AddTestimonial";
import AllTestimonials from "./containers/testimonials/AllTestimonials";
import ViewTestimonial from "./containers/testimonials/ViewTestimonial";
import EditTestimonial from "./containers/testimonials/EditTestimonial";

import AddCategory from "./containers/categorys/AddCategory";
import AllCategorys from "./containers/categorys/AllCategorys";
import ViewCategory from "./containers/categorys/ViewCategory";
import EditCategory from "./containers/categorys/EditCategory";

import AddReview from "./containers/reviews/AddReview";
import AllReviews from "./containers/reviews/AllReviews";
import ViewReview from "./containers/reviews/ViewReview";
import EditReview from "./containers/reviews/EditReview";

import Menu from "./containers/menus-old/Menu";
import Settings from "./containers/settings/Settings";

import AddMenu from "./containers/menus/AddMenu";
import AllMenus from "./containers/menus/AllMenus";
import ViewMenu from "./containers/menus/ViewMenu";
import EditMenu from "./containers/menus/EditMenu";

import AddAppointment from "./containers/appointments/AddAppointment";
import AllAppointments from "./containers/appointments/AllAppointments";
import ViewAppointment from "./containers/appointments/ViewAppointment";
import EditAppointment from "./containers/appointments/EditAppointment";

import Webpage from "./containers/webpages/Webpage";

import AddFaq from "./containers/faqs/AddFaq";
import AllFaqs from "./containers/faqs/AllFaqs";
import ViewFaq from "./containers/faqs/ViewFaq";
import EditFaq from "./containers/faqs/EditFaq";


function App() {
  useEffect(() => {
    //First we have to bring(get that) token, which is saved in local storage
    const token = localStorage.getItem("token");
    //then we will pass that token in setAuthToken method
    setAuthToken(token);
    store.dispatch(loadUser());
  }, []);

  return (
    <Provider store={store}>
      <Router>
        <Switch>
          <BeforeLoginRoutes exact path="/" component={Login} />
          <PrivateRoutes exact path="/dashboard" component={Dashboard} />
          <PrivateRoutes exact path="/profile" component={Profile} />

          <PrivateRoutes exact path="/banners" component={AllBanners} />
          <PrivateRoutes exact path="/banners/add" component={AddBanner} />
          <PrivateRoutes
            exact
            path="/banners/:id/view"
            component={ViewBanner}
          />
          <PrivateRoutes
            exact
            path="/banners/:id/edit"
            component={EditBanner}
          />

          <PrivateRoutes exact path="/contacts" component={AllContacts} />
          <PrivateRoutes exact path="/contacts/add" component={AddContact} />
          <PrivateRoutes
            exact
            path="/contacts/:id/view"
            component={ViewContact}
          />
          <PrivateRoutes
            exact
            path="/contacts/:id/edit"
            component={EditContact}
          />

          <PrivateRoutes exact path="/newsletters" component={AllNewsletters} />
          <PrivateRoutes
            exact
            path="/newsletters/add"
            component={AddNewsletter}
          />
          <PrivateRoutes
            exact
            path="/newsletters/:id/view"
            component={ViewNewsletter}
          />
          <PrivateRoutes
            exact
            path="/newsletters/:id/edit"
            component={EditNewsletter}
          />

          <Route exact path="/categorys" component={AllCategorys} />
          <Route
            exact
            path="/categorys/add"
            component={AddCategory}
          />
          <Route
            exact
            path="/categorys/:id/view"
            component={ViewCategory}
          />
          <Route
            exact
            path="/categorys/:id/edit"
            component={EditCategory}
          />


          <PrivateRoutes exact path="/blogs" component={AllBlogs} />
          <PrivateRoutes exact path="/blogs/add" component={AddBlog} />
          <PrivateRoutes exact path="/blogs/:id/view" component={ViewBlog} />
          <PrivateRoutes exact path="/blogs/:id/edit" component={EditBlog} />

          <Route exact path="/reviews" component={AllReviews} />
          <Route
            exact
            path="/reviews/add"
            component={AddReview}
          />
          <Route
            exact
            path="/reviews/:id/view"
            component={ViewReview}
          />
          <Route
            exact
            path="/reviews/:id/edit"
            component={EditReview}
          />

          <PrivateRoutes exact path="/collections" component={AllCollections} />
          <PrivateRoutes
            exact
            path="/collections/add"
            component={AddCollection}
          />
          <PrivateRoutes
            exact
            path="/collections/:id/view"
            component={ViewCollection}
          />
          <PrivateRoutes
            exact
            path="/collections/:id/edit"
            component={EditCollection}
          />

          <PrivateRoutes exact path="/services" component={AllServices} />
          <PrivateRoutes
            exact
            path="/services/add"
            component={AddService}
          />
          <PrivateRoutes
            exact
            path="/services/:id/view"
            component={ViewService}
          />
          <PrivateRoutes
            exact
            path="/services/:id/edit"
            component={EditService}
          />
          {/* <PrivateRoutes exact path="/menus/:slug/edit" component={Menu} />
          <PrivateRoutes exact path="/menus/add" component={Menu} />
          <PrivateRoutes exact path="/menus" component={Menu} /> */}

          <PrivateRoutes exact path="/menus" component={AllMenus} />
          <PrivateRoutes exact path="/menus/add" component={AddMenu} />
          <PrivateRoutes exact path="/menus/:id/view" component={ViewMenu} />
          <PrivateRoutes exact path="/menus/:id/edit" component={EditMenu} />
          <PrivateRoutes exact path="/settings" component={Settings} />

          <PrivateRoutes
            exact
            path="/mobilebanners"
            component={AllMobilebanners}
          />
          <PrivateRoutes
            exact
            path="/mobilebanners/add"
            component={AddMobilebanner}
          />
          <PrivateRoutes
            exact
            path="/mobilebanners/:id/view"
            component={ViewMobilebanner}
          />
          <PrivateRoutes
            exact
            path="/mobilebanners/:id/edit"
            component={EditMobilebanner}
          />

          <PrivateRoutes
            exact
            path="/testimonials"
            component={AllTestimonials}
          />
          <PrivateRoutes
            exact
            path="/testimonials/add"
            component={AddTestimonial}
          />
          <PrivateRoutes
            exact
            path="/testimonials/:id/view"
            component={ViewTestimonial}
          />
          <PrivateRoutes
            exact
            path="/testimonials/:id/edit"
            component={EditTestimonial}
          />

          <PrivateRoutes exact path="/appointments" component={AllAppointments} />
          <PrivateRoutes
            exact
            path="/appointments/add"
            component={AddAppointment}
          />
          <PrivateRoutes
            exact
            path="/appointments/:id/view"
            component={ViewAppointment}
          />
          <PrivateRoutes
            exact
            path="/appointments/:id/edit"
            component={EditAppointment}
          />
          <PrivateRoutes
            exact
            path="/webpages"
            component={Webpage}
          />

          <PrivateRoutes exact path="/faqs" component={AllFaqs} />
          <PrivateRoutes
            exact
            path="/faqs/add"
            component={AddFaq}
          />
          <PrivateRoutes
            exact
            path="/faqs/:id/view"
            component={ViewFaq}
          />
          <PrivateRoutes
            exact
            path="/faqs/:id/edit"
            component={EditFaq}
          />

          <Route component={PageNotFound} />
        </Switch>
      </Router>
    </Provider>
  );
}

export default App;
