export const PAGE_TITLE = "Faqs";
export const PAGE_SINGLE_TITLE = "Faq";
export const LINK_URL = "faqs";
export const inputFields = {
    question: {
        type: "string",
        required: true,
        title: "Question",
        inputType: "text",
        slug: true,
    },
    answer: {
        type: "string",
        required: true,
        title: "Answer",
        inputType: "text",
        slug: true,
    },
    meta_title: {
        type: "string",
        required: false,
        title: "Meta Title",
    },
    meta_desc: {
        type: "string",
        required: false,
        title: "Meta Description",
    },

};
export const initialValues = {
    question: "",
    answer: "",
};

export const view_all_table = [
    { name: "Question", value: "question" },
    { name: "Answer", value: "answer" },
];

export const SIDEBAR_OPTIONS = [
    {
        id: "question",
        field: "question",
        label: "Question",
        type: "string",
        search_type: "search",
        inputType: "text",
        condition: "",
    },
];
