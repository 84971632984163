import React, { useEffect } from "react";
import BreadCrumb from "../../components/template/BreadCrumb";
import Header from "../../components/template/Header";
import Spinner from "../../components/layout/Spinner";
import {
    inputFields,
    PAGE_TITLE,
    PAGE_SINGLE_TITLE,
    LINK_URL,
    SIDEBAR_OPTIONS,
} from "../../shared/enums/services_enum";
import SingleView from "../../components/common/SingleView";
import { useSingleService } from "../../shared/hooks/UseService";
const ViewService = ({ match }) => {
    const [data] = useSingleService(match.params.id);
    const { service_loading, service } = data;
    return (
        <div className="pace-done">
            <div>
                <Header />
                <BreadCrumb
                    title={PAGE_SINGLE_TITLE}
                    mainLinkTitle={PAGE_TITLE}
                    mainLinkUrl={LINK_URL}
                    activeLink="View"
                />
                {!service_loading ? (
                    service && (
                        <SingleView
                            data={service}
                            inputFields={inputFields}
                            label={PAGE_SINGLE_TITLE}
                            link={LINK_URL}
                            id={service._id}
                            SIDEBAR_OPTIONS={SIDEBAR_OPTIONS}
                        />
                    )
                ) : (
                    <div>
                        <Spinner />
                    </div>
                )}
            </div>
        </div>
    );
};

export default ViewService;
