import React, { useState, useEffect } from "react";
import BreadCrumb from "../../components/template/BreadCrumb";
import Header from "../../components/template/Header";
import { useHistory } from "react-router-dom";
import WebpageForm from "../../components/Webpages/WebpageForm";
import { convertToFormData } from "../../shared/upload";
import {
    initialValues,
    inputFields,
    PAGE_TITLE,
    PAGE_SINGLE_TITLE,
    LINK_URL,
} from "../../shared/enums/webpages_enum";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import {
    TextInput,
    HTMLEditor,
    FileUpload
} from "../../components/Form/Form";
import { useCreateWebpage, useGetWebpage } from "../../shared/hooks/UseWebpage"
// import { useSelectAllWebpage } from "../../shared/hooks/UseWebpage";

const Webpage = ({ }) => {
    const [webpage_data] = useGetWebpage()
    const [webpage, addData] = useCreateWebpage();
    const { webpages, webpages_loading } = webpage;
    const [featuredImage, setFeaturedImage] = useState(null);
    const [gallery, setGallery] = useState(null);

    console.log("Webpages mai kya hai -", webpages);

    const submitFormClicked = async (values) => {
        await addData(values);
        // history.push(`/${LINK_URL}`);
    };


    return (
        <div className="pace-done">
            <div>
                <Header />
                <BreadCrumb
                    title={`Webpage`}
                    mainLinkTitle={`Webpage`}
                    mainLinkUrl={`/webpages`}
                    activeLink="Add"
                />
            </div>
            <section>
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-12">
                            {!webpages_loading && webpages && (
                                <Formik
                                    initialValues={{
                                        logo: webpages && webpages.logo ? webpages.logo : "",
                                        facebook_link: webpages && webpages.facebook_link ? webpages.facebook_link : "",
                                        instagram_link: webpages && webpages.instagram_link ? webpages.instagram_link : "",
                                        twitter_link: webpages && webpages.twitter_link ? webpages.twitter_link : "",
                                        linkedin_link: webpages && webpages.linkedin_link ? webpages.linkedin_link : "",
                                        youtube_link: webpages && webpages.youtube_link ? webpages.youtube_link : "",
                                        address: webpages && webpages.address ? webpages.address : "",
                                        contact_no1: webpages && webpages.contact_no1 ? webpages.contact_no1 : "",
                                        contact_no2: webpages && webpages.contact_no2 ? webpages.contact_no2 : "",
                                        email_address: webpages && webpages.email_address ? webpages.email_address : "",
                                        map_integrate_links: webpages && webpages.map_integrate_links ? webpages.map_integrate_links : "",
                                        privacy_policy: webpages && webpages.privacy_policy ? webpages.privacy_policy : "",
                                        refund_policy: webpages && webpages.refund_policy ? webpages.refund_policy : "",
                                        who_we_are_image: webpages && webpages.who_we_are_image ? webpages.who_we_are_image : "",
                                        who_we_are_heading: webpages && webpages.who_we_are_heading ? webpages.who_we_are_heading : "",
                                        who_we_are_content: webpages && webpages.who_we_are_content ? webpages.who_we_are_content : "",
                                        who_we_are_content_2: webpages && webpages.who_we_are_content_2 ? webpages.who_we_are_content_2 : "",
                                        doctor_image: webpages && webpages.doctor_image ? webpages.doctor_image : "",
                                        doctor_name: webpages && webpages.doctor_name ? webpages.doctor_name : "",
                                        doctor_job_role: webpages && webpages.doctor_job_role ? webpages.doctor_job_role : "",
                                        doctor_content: webpages && webpages.doctor_content ? webpages.doctor_content : "",

                                    }}
                                    validationSchema={Yup.object({
                                        logo: Yup.string().required("Required"),
                                    })}
                                    onSubmit={async (
                                        values,
                                        { setSubmitting, resetForm }
                                    ) => {
                                        setSubmitting(true);
                                        await submitFormClicked(values)
                                        resetForm();
                                        setSubmitting(false);
                                    }}
                                >
                                    {(formik) => {
                                        console.log(formik);
                                        return (
                                            <>
                                                <section>
                                                    <div className="card">
                                                        <div className="card-header">
                                                            <h4 className="card-title">
                                                                Webpage
                                                            </h4>
                                                            <p className="card-title-description">
                                                                Enter Details to add Webpage
                                                            </p>
                                                        </div>
                                                        <div className="card-body">
                                                            <div className="row">
                                                                <Form>
                                                                    <div className="row">
                                                                        <div className="col-md-6">
                                                                            <FileUpload
                                                                                title="Logo"
                                                                                item="logo"
                                                                                formik={formik}
                                                                                value={formik.values.logo}
                                                                            />
                                                                        </div>
                                                                        <div className="mt-3">
                                                                            <h5>Social Links For Rejuve:</h5>
                                                                        </div>
                                                                        <div className="col-md-6">
                                                                            <TextInput
                                                                                label="Facebook Link"
                                                                                name="facebook_link"
                                                                                type="text"
                                                                                placeholder="Enter Facebook Link"
                                                                            />
                                                                        </div>
                                                                        <div className="col-md-6">
                                                                            <TextInput
                                                                                label="Instagram Link"
                                                                                name="instagram_link"
                                                                                type="text"
                                                                                placeholder="Enter Instagram Link"
                                                                            />
                                                                        </div>
                                                                        <div className="col-md-6">
                                                                            <TextInput
                                                                                label="Twitter Link"
                                                                                name="twitter_link"
                                                                                type="text"
                                                                                placeholder="Enter Twitter Link"
                                                                            />
                                                                        </div>
                                                                        <div className="col-md-6">
                                                                            <TextInput
                                                                                label="LinkedIn Link"
                                                                                name="linkedin_link"
                                                                                type="text"
                                                                                placeholder="Enter LinkedIn Link"
                                                                            />
                                                                        </div>
                                                                        <div className="col-md-6">
                                                                            <TextInput
                                                                                label="Youtube Link"
                                                                                name="youtube_link"
                                                                                type="text"
                                                                                placeholder="Enter Youtube Link"
                                                                            />
                                                                        </div>
                                                                        <div className="mt-3">
                                                                            <h5>Contact Details:</h5>
                                                                        </div>
                                                                        <div className="col-md-6">
                                                                            <TextInput
                                                                                label="Address"
                                                                                name="address"
                                                                                type="text"
                                                                                placeholder="Enter Store Address"
                                                                            />
                                                                        </div>
                                                                        <div className="col-md-6">
                                                                            <TextInput
                                                                                label="Contact Number 1"
                                                                                name="contact_no1"
                                                                                type="text"
                                                                                placeholder="Enter Contact Number 1"
                                                                            />
                                                                        </div>
                                                                        <div className="col-md-6">
                                                                            <TextInput
                                                                                label="Contact Number 2"
                                                                                name="contact_no2"
                                                                                type="text"
                                                                                placeholder="Enter Contact Number 2"
                                                                            />
                                                                        </div>
                                                                        <div className="col-md-6">
                                                                            <TextInput
                                                                                label="Email Address"
                                                                                name="email_address"
                                                                                type="text"
                                                                                placeholder="Enter Email Address"
                                                                            />
                                                                        </div>
                                                                        <div className="col-md-6">
                                                                            <TextInput
                                                                                label="Map Integrate Link"
                                                                                name="map_integrate_links"
                                                                                type="text"
                                                                                placeholder="Enter Map Integrate Link"
                                                                            />

                                                                        </div>
                                                                        <div className="mt-3">
                                                                            <h5>Privacy Policy Content:</h5>
                                                                        </div>
                                                                        <div className="col-md-12">
                                                                            <HTMLEditor
                                                                                title="Privacy Policy Content"
                                                                                item="privacy_policy"
                                                                                formik={formik}
                                                                                value={formik.values.privacy_policy}
                                                                            />
                                                                        </div>
                                                                        <div className="mt-3">
                                                                            <h5>Terms & Conditions Content:</h5>
                                                                        </div>
                                                                        <div className="col-md-12">
                                                                            <HTMLEditor
                                                                                title="Terms and Conditions"
                                                                                item="privacy_policy"
                                                                                formik={formik}
                                                                                value={formik.values.terms_and_conditions}
                                                                            />
                                                                        </div>
                                                                        <div className="mt-3">
                                                                            <h5>Who we are Section Details:</h5>
                                                                        </div>
                                                                        <div className="col-md-12">
                                                                            <FileUpload
                                                                                title="Who we are Image"
                                                                                item="who_we_are_image"
                                                                                formik={formik}
                                                                                value={formik.values.who_we_are_image}
                                                                            />
                                                                        </div>
                                                                        <div className="col-md-6">
                                                                            <TextInput
                                                                                label="Who we are Heading"
                                                                                name="who_we_are_heading"
                                                                                type="text"
                                                                                placeholder="Enter Who we are Heading"
                                                                            />
                                                                        </div>
                                                                        <div className="col-md-12">
                                                                            <HTMLEditor
                                                                                title="Who we are Content"
                                                                                item="who_we_are_content"
                                                                                formik={formik}
                                                                                value={formik.values.who_we_are_content}
                                                                            />
                                                                        </div>
                                                                        <div className="col-md-12">
                                                                            <HTMLEditor
                                                                                title="Who we are Content 2"
                                                                                item="who_we_are_content_2"
                                                                                formik={formik}
                                                                                value={formik.values.who_we_are_content_2}
                                                                            />
                                                                        </div>
                                                                        <div className="mt-3">
                                                                            <h5>Team Section Details:</h5>
                                                                        </div>
                                                                        <div className="col-md-6">
                                                                            <FileUpload
                                                                                title="Dr. Image"
                                                                                item="doctor_image"
                                                                                formik={formik}
                                                                                value={formik.values.doctor_image}
                                                                            />
                                                                        </div>
                                                                        <div className="col-md-6">
                                                                            <TextInput
                                                                                label="Doctor Name"
                                                                                name="doctor_name"
                                                                                type="text"
                                                                                placeholder="Enter Doctor Name"
                                                                            />
                                                                        </div>
                                                                        <div className="col-md-6">
                                                                            <TextInput
                                                                                label="Dr.Job Role"
                                                                                name="doctor_job_role"
                                                                                type="text"
                                                                                placeholder="Enter Dr.Job Role"
                                                                            />
                                                                        </div>
                                                                        <div className="col-md-12">
                                                                            <HTMLEditor
                                                                                title="Dr. Content"
                                                                                item="doctor_content"
                                                                                formik={formik}
                                                                                value={formik.values.doctor_content}
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                    <div className="row">
                                                                        <div className="col-md-12 text-center m-3">
                                                                            <button type="submit" className="btn btn-success">
                                                                                {formik.isSubmitting
                                                                                    ? 'Processing...'
                                                                                    : 'Save'}
                                                                            </button>
                                                                        </div>
                                                                    </div>
                                                                </Form>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </section>
                                            </>
                                        );
                                    }}
                                </Formik>
                            )}
                        </div>
                    </div>
                </div>
            </section>
        </div>
    );
};

export default Webpage;
